import React from 'react';
import { useToast } from './ToastContext';
import BodyText from '../text/body-text/body-text';

const Toast = () => {
  const { toast, hideToast } = useToast();
  const { message, type, visible } = toast;

  if (!visible) return null;

  const typeStyles = {
    success: 'bg-green-100 border-green-500 text-green-700',
    error: 'bg-red-100 border-red-500 text-red-700',
    info: 'bg-blue-100 border-blue-500 text-blue-700',
  };

  return (
    <div style={{ zIndex: 150 }} className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 border-l-4 ${typeStyles[type]}`}>
      <div className="flex items-center justify-between gap-3">
        <BodyText variant="bodyText7" className="flex-1">{message}</BodyText>
        <button onClick={hideToast} className='cursor-pointer'>
          ✖
        </button>
      </div>
    </div>
  );
};

export default Toast;
