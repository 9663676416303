import React from 'react';
import * as styles from './body-text.module.css';

const BodyText = ({ children, className, color, variant="bodyText6" }) => {
  const combinedClasses = `${styles[variant]}  ${className || ''} text-[${color || '#333'}]`;

  return (
    <div className={combinedClasses}>
      {children}
    </div>
  );
};

export default BodyText;