exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-geneo-index-js": () => import("./../../../src/pages/geneo/index.js" /* webpackChunkName: "component---src-pages-geneo-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-management-system-index-js": () => import("./../../../src/pages/learning-management-system/index.js" /* webpackChunkName: "component---src-pages-learning-management-system-index-js" */),
  "component---src-pages-more-index-js": () => import("./../../../src/pages/more/index.js" /* webpackChunkName: "component---src-pages-more-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-smart-classroom-index-js": () => import("./../../../src/pages/smart-classroom/index.js" /* webpackChunkName: "component---src-pages-smart-classroom-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

