/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import './global.css'
import { ToastProvider } from './src/components/toast/ToastContext';
import Toast from './src/components/toast/toast';

export const wrapRootElement = ({ element }) => (
    <ToastProvider>
      {element}
      <Toast />
    </ToastProvider>
  );